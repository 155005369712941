export const urls = [
  'http://www.isitfridayyet.net',
     'https://90s.myretrotvs.com/',
     'https://accessmars.withgoogle.com/',
     'http://www.isitfridayyet.net',
       'https://90s.myretrotvs.com/',
       'https://accessmars.withgoogle.com/',
       'https://alteredqualia.com/xg/examples/albert.html',
       'https://alteredqualia.com/xg/examples/text_rendering.html',
       'https://beepjs.com/',
       'https://binarypiano.com',
       'https://blackscreen.app',
       'https://boringboringboring.com',
       'https://bouncyballs.org',
       'https://burymewithmymoney.com',
       'https://cachemonet.com/',
       'https://cant-not-tweet-this.com',
       'https://carecards.io/',
       'https://cat-bounce.com',
       'https://checkboxrace.com',
       'https://chrismckenzie.com',
       'https://citywalks.live/',
       'https://clickclickclick.click/',
       'https://corndogoncorndog.com',
       'https://d21u3ic0kp9e91.cloudfront.net/choir/04/index.html',
       'https://dadlaughbutton.com',
       'https://dirac182.github.io/movie-span/',
       'https://drumsound.net',
       'https://e.ggtimer.com/',
       'https://earth.nullschool.net/',
       'https://edufall.github.io/o1/make-it-meme/#/',
       'https://elementfinder.info/',
       'https://endlessdoomscroller.com',
       'https://epic.horse',
       'https://everysecond.io/animal-heartbeats',
       'https://existentialcrisis.com',
       'https://fakeupdate.net/',
       'https://ffffidget.com',
       'https://fritzo.org/keys/#style=piano',
       'https://funny-jokes.netlify.app/',
       'https://getwellsoon.labr.io/',
       'https://goldfishies.com/',
       'https://greatbignothing.com',
       'https://hackertyper.com',
       'https://hammyhome.com/',
       'https://humanbenchmark.com/',
       'https://instantostrich.com',
       'https://is-it-friday.org/',
       'https://isitchristmas.com',
       'https://isittimeforanap.com/',
       'https://jacksonpollock.org',
       'https://jinay.dev/just-a-minute/',
       'https://joshdata.me/iceberger.html',
       'https://justflipacoin.com',
       'https://krikienoid.github.io/flagwaver/',
       'https://lab.djordjeungar.com/experiment/gearworks',
       'https://large-type.com/',
       'https://littlealchemy.com',
       'https://longdogechallenge.com',
       'https://maxwellcat.world',
       'https://more.playables.net',
       'https://mrdoob.com/lab/javascript/checkbox_painter/',
       'https://mrdoob.com/lab/javascript/effects/water/02/',
       'https://mrdoob.com/lab/javascript/webgl/sphere/',
       'https://neal.fun/asteroid-launcher/',
       'https://neal.fun/earth-reviews/',
       'https://neal.fun/life-stats/',
       'https://neal.fun/paper/',
       'https://neal.fun/password-game/',
       'https://neal.fun/progress/',
       'https://neal.fun/share-this-page/',
       'https://neal.fun/size-of-space/',
       'https://neal.fun/who-was-alive/',
       'https://nodtotherhythm.com/',
       'https://nullingthevoid.com',
       'https://oimo.io/works/bubbles/',
       'https://onesquareminesweeper.com',
       'https://oskarstalberg.com/game/CityGenerator/',
       'https://papertoilet.com',
       'https://perpetual.pizza/',
       'https://petswitch.com/',
       'https://pointerpointer.com',
       'https://popcat.click',
       'https://postsecret.com/',
       'https://potato.io',
       'https://potatoortomato.com',
       'https://pranx.com/sound-effects/',
       'https://procatinator.com/',
       'https://protobot.org/',
       'https://pudding.cool/2018/11/boy-bands/',
       'https://pudding.cool/games/where/',
       'https://puginarug.com',
       'https://radio.garden/',
       'https://randomcolour.com/',
       'https://recipescal.com/',
       'https://remoji.com',
       'https://rotaboxes.com/',
       'https://rotatingsandwiches.com',
       'https://sciencevsmagic.net/tes/',
       'https://scientific.place/math-crossword/',
       'https://screamclub.club/',
       'https://screamintothevoid.com',
       'https://sequencer.henryfellerhoff.com/',
       'https://simplify.thatsh.it/',
       'https://skulltrumpet.com',
       'https://sliding.toys/klotski/easy-street/',
       'https://smashthewalls.com',
       'https://spinningrat.online',
       'https://supertunnel.app/',
       'https://tacospin.com',
       'https://takeb1nzyto.space',
       'https://textfancy.com/',
       'https://thanaverage.xyz/',
       'https://tilti.me',
       'https://timesnewerroman.com/',
       'https://touchpianist.com/',
       'https://typethealphabet.app/',
       'https://vole.wtf/kilogram/',
       'https://whatthefuckshouldimakefordinner.com/',

       'https://willyoupressthebutton.com',
       'https://wouldyourather.vorensstudios.com/',
       'https://wvnl.xyz/hereistoday/',
       'https://www.artsology.com/lightning/drawing-with-lightning.html',
       'https://www.astrohamster.com',
       'https://www.biglongnow.com/',
       'https://www.blankwindows.com',
       'https://www.burymewithmymoney.com',
       'https://www.coincalc.com/',
       'https://www.crazycardtrick.com',
       'https://www.d-e-f-i-n-i-t-e-l-y.com',
       'https://www.doesthedogdie.com',
       'https://www.dramabutton.com',
       'https://www.emergencycompliment.com/#',
       'https://www.emojiphrasebook.com',
       'https://www.existentialcrisis.com',
       'https://www.fallingfalling.com',
       'https://www.flyingfrying.com/',
       'https://www.geokitten.com',
       'https://www.hectorsalamanca.com',
       'https://www.hiyoooo.com',
       'https://www.howmanypeopleareinspacerightnow.com/',
       'https://www.iamawesome.com',
       'https://www.iamveryverysorry.com/',
       'https://www.lacquerlacquer.com',
       'https://www.lookingatsomething.com/',
       'https://www.maninthedark.com',
       'https://www.mapcrunch.com',
       'https://www.misternicehands.com/',
       'https://www.movenowthinklater.com',
       'https://www.muchbetterthanthis.com',
       'https://www.music-map.com/',
       'https://www.omfgdogs.com/',
       'https://www.onandoff.org/',
       'https://www.openthiswindow.com',
       'https://www.perry.qa/morse',
       'https://www.pictureofhotdog.com',
       'https://www.popcornpainting.com/',
       'https://www.randomgoat.com',
       'https://www.riffusion.com/record',
       'https://www.rock-paper-scissors-game.com',
       'https://www.rrrgggbbb.com',
       'https://www.something.com',
       'https://www.thedougscore.co/',
       'https://www.thesongthatdefinesyourlife.com',
       'https://www.thisisnotajumpscare.com',
       'https://www.thispersondoesnotexist.com',
       'https://www.tothewater.com/',
       'https://www.watching-grass-grow.com/',
       'https://www.webdev20.pl/skins/default/js/demos/solar_system/index.html',
       'https://www.whattaclick.com',
       'https://www.whywashesad.com/',
       'https://www.windows93.net/',
       'https://www.worldsdumbestgame.com',
       'https://www.wtfshouldidowithmylife.com/',
       'https://wxs.ca/iso/',
       'https://zoomquilt.org',
    'https://www.thetruesize.com/',
    'https://www.worldometers.info/',
    'https://gameze.io/type/',
    'https://recipescal.com/',
    'https://www.tomscott.com/usvsth3m/north-o-meter/',
    'https://www.tomscott.com/usvsth3m/maths/',
    'https://www.tomscott.com/usvsth3m/owen-patersons-badger-penalty-shootout/',
    'https://www.tomscott.com/usvsth3m/realistic-facebook-privacy-simulator/',
    'https://www.tomscott.com/usvsth3m/are-you-hated-by-the-daily-mail/',
    'https://www.tomscott.com/usvsth3m/edballs/',
    'https://mattround.com/usvsth3m/1000-seconds/',
    'https://mattround.com/usvsth3m/shine-o-matic/',
    'https://mattround.com/usvsth3m/granagotchi/',
  // ... rest of your URLs
];

export const gameUrls = [
  'https://littlealchemy.com',
    'https://checkboxrace.com',
    'https://www.rock-paper-scissors-game.com',
    'https://www.worldsdumbestgame.com',
    'https://23azostore.github.io/s/ovo/',
    'https://23azostore.github.io/s5/jumping-shell/',
    'https://ad-freegames.github.io/flash/game/mini-putt.html',
    'https://archive777.com/2563225775147/Crazy%20Wheel/',
    'https://archive777.com/2563225775147/Handless%20Millionaire/',
    'https://bitlifeonline.github.io/class12523/bow-mania/',
    'https://bitlifeonline.github.io/ovo-dimensions/',
    'https://boxing2.github.io/b2/brain-test-tricky-puzzles/',
    'https://boxing2.github.io/b3/temple-of-boom/',
    'https://boxing2.github.io/b6/stick-fighter/',
    'https://boxing2.github.io/b88/friday-night-funkin/',
    'https://cdn.gamesnacks.com/drawclimber1/gameCode/index.html',
    'https://classroom-6x.org/games/bullet-bros/',
    'https://classroomjq.github.io/soccer-skills-world-cup/',
    'https://edufall.github.io/o1/flip-bros/',
    'https://edufall.github.io/o2/blumgi-castle/',
    'https://edufall.github.io/o20/tiny-fishing/',
    'https://edufall.github.io/o26/trivia-crack/',
    'https://edufall.github.io/o77/ragdoll-hit/',
    'https://eggy-car.github.io/game10/gold-digger-frvr/',
    'https://eggy-car.github.io/game2/candy-jump/',
    'https://emulatoros.github.io/gfile/snake/',
    'https://ev.io/',
    'https://game316009.konggames.com/gamez/0031/6009/live/index.html',
    'https://htmlxm.github.io/h2/burger-bounty/',
    'https://iclouds.space/FLASH/swf.php?filename=the_worlds_hardest_game_ad.swf',
    'https://krunker.io/',
    'https://lab.hakim.se/fullstack/',
    'https://rebemanae.github.io/rooftop-snipers-2/',
    'https://s3.mirror.co.uk/click-the-colour-and-not-the-word/index.html',
    'https://sd592g.github.io/zj684od4lfg/',
    'https://slope-game.github.io/arcane-archer/',
    'https://slope-game.github.io/newgame/monkey-mart/',
    'https://slope-game.github.io/sniper-code-2/',
    'https://smashkartsonline.github.io/v/smash-karts-2.5.0/',
    'https://szhong.4399.com/4399swf//upload_swf/ftp41/liuxinyu/20221123/9/index.html',
    'https://therace.montblanclegend.com/en-us',
    'https://ubg365.github.io/stair-race-3d/',
    'https://ubg77.github.io/edit/rocket-soccer-derby/',
    'https://ubg77.github.io/fix/parkour-race/',
    'https://ubg77.github.io/game131022/thumb-fighter/',
    'https://ubg77.github.io/war-of-sticks/',
    'https://ubg98.github.io/Roper/',
    'https://unicyclehero.github.io/file/',
    'https://unicyclehero.github.io/s2/tinytownracing/',
    'https://webglmath.github.io/house-of-hazards/',
    'https://webglmath.github.io/raft-wars/',
    'https://webglmath.github.io/stickmanhook/',
    'https://webglmath.github.io/tunnel-rush-2/',
    'https://worldle.teuteuf.fr/',
    'https://www.kodub.com/apps/polytrack',
    'https://www.michaelbromley.co.uk/experiments/css-space-shooter/',
  // ... rest of your game URLs
];

export const productUrls = [
  'https://www.amazon.com/Blppldyci-Potato-Spring-Halloween-Decoration/dp/B07ZFNQS65/',
  'https://www.amazon.com/Coddies-Flops-Unisex-Slippers-Sandals/dp/B07CT4WJ71/',
  'https://www.amazon.com/dp/0762459816/',
  'https://www.amazon.com/dp/0762473754/',
  'https://www.amazon.com/dp/0789332906/',
  'https://www.amazon.com/dp/0811855511/',
  'https://www.amazon.com/dp/0963810952/',
  'https://www.amazon.com/dp/3836526603/',
  'https://www.amazon.com/dp/B0001JX9IU/',
  'https://www.amazon.com/dp/B000CIU7H6/',
  'https://www.amazon.com/dp/B000I1V25S/',
  'https://www.amazon.com/dp/B0010VS078/',
  'https://www.amazon.com/dp/B0013D8VDQ/',
  'https://www.amazon.com/dp/B001T8OFQM/',
  'https://www.amazon.com/dp/B004P4VUVU/',
  'https://www.amazon.com/dp/B005BYFLHC/',
  'https://www.amazon.com/dp/B007YNBOJM/',
  'https://www.amazon.com/dp/B0085NX5GU/',
  'https://www.amazon.com/dp/B00A2AL9AI/',
  'https://www.amazon.com/dp/B00C2294J2/',
  'https://www.amazon.com/dp/B00FNWRWT2/',
  'https://www.amazon.com/dp/B00G6UHY7A/',
  'https://www.amazon.com/dp/B00GTB1X8C/',
  'https://www.amazon.com/dp/B00HGU5D8G/',
  'https://www.amazon.com/dp/B00I0VULXC/',
  'https://www.amazon.com/dp/B00IXXJGAQ/',
  'https://www.amazon.com/dp/B00JLWHVEQ/',
  'https://www.amazon.com/dp/B00K6JPJB0/',
  'https://www.amazon.com/dp/B00PDGF646/',
  'https://www.amazon.com/dp/B00V7UICUW/',
  'https://www.amazon.com/dp/B00XTH7SSS/',
  'https://www.amazon.com/dp/B01065RMPY/',
  'https://www.amazon.com/dp/B010TQY7A8/',
  'https://www.amazon.com/dp/B012HFHE1S/',
  'https://www.amazon.com/dp/B015PSIRW4/',
  'https://www.amazon.com/dp/B0168Q68BY/',
  'https://www.amazon.com/dp/B016CDA84G/',
  'https://www.amazon.com/dp/B016V6TAZC/',
  'https://www.amazon.com/dp/B018PIKF7G/',
  'https://www.amazon.com/dp/B01AISXOOM/',
  'https://www.amazon.com/dp/B01BEZTAGE/',
  'https://www.amazon.com/dp/B01FO7K4YA/',
  'https://www.amazon.com/dp/B01GPU9DP6/',
  'https://www.amazon.com/dp/B01KU8DEX6/',
  'https://www.amazon.com/dp/B01MZY4S89/',
  'https://www.amazon.com/dp/B01N29DRSH/',
  'https://www.amazon.com/dp/B01NBNP92R/',
  'https://www.amazon.com/dp/B06XK3297S/',
  'https://www.amazon.com/dp/B071X8S8WQ/',
  'https://www.amazon.com/dp/B072NBS55S/',
  'https://www.amazon.com/dp/B0731QBCD5/',
  'https://www.amazon.com/dp/B0748L3CKK/',
  'https://www.amazon.com/dp/B0758358X6/',
  'https://www.amazon.com/dp/B07586Y1T9/',
  'https://www.amazon.com/dp/B075VXNSHZ/',
  'https://www.amazon.com/dp/B076PRZWCV/',
  'https://www.amazon.com/dp/B076SPDHDQ/',
  'https://www.amazon.com/dp/B079618L3W/',
  'https://www.amazon.com/dp/B079QB81ZH/',
  'https://www.amazon.com/dp/B079YXYSLN/',
  'https://www.amazon.com/dp/B07BP1G35P/',
  'https://www.amazon.com/dp/B07BVC4KMT/',
  'https://www.amazon.com/dp/B07BVVFZYY/',
  'https://www.amazon.com/dp/B07BYN1N21/',
  'https://www.amazon.com/dp/B07C9R5ST8/',
  'https://www.amazon.com/dp/B07GH953JN/',
  'https://www.amazon.com/dp/B07H39C21C/',
  'https://www.amazon.com/dp/B07H75BJWW/',
  'https://www.amazon.com/dp/B07HRTHZCB/',
  'https://www.amazon.com/dp/B07HRYM9JS/',
  'https://www.amazon.com/dp/B07K2SYDJ1/',
  'https://www.amazon.com/dp/B07KCQFTRC/',
  'https://www.amazon.com/dp/B07LBMG77S/',
  'https://www.amazon.com/dp/B07LC23SVM/',
  'https://www.amazon.com/dp/B07ND17D16/',
  'https://www.amazon.com/dp/B07NP2Y6X5/',
  'https://www.amazon.com/dp/B07NQ3GTTW/',
  'https://www.amazon.com/dp/B07P7JN6VR/',
  'https://www.amazon.com/dp/B07R1TPZYH/',
  'https://www.amazon.com/dp/B07SBY2215/',
  'https://www.amazon.com/dp/B07SSW1CLM/',
  'https://www.amazon.com/dp/B07VZ2HB6P/',
  'https://www.amazon.com/dp/B07XSNW3SQ/',
  'https://www.amazon.com/dp/B07XWNCZWM/',
  'https://www.amazon.com/dp/B07YZ1F4PB/',
  'https://www.amazon.com/dp/B082BG37HR/',
  'https://www.amazon.com/dp/B089ZG6371/',
  'https://www.amazon.com/dp/B089ZG6371/',
  'https://www.amazon.com/dp/B08QDSZVT2/',
  'https://www.amazon.com/dp/B08QG8VMK3/',
  'https://www.amazon.com/dp/B08ZHCDZMY/',
  'https://www.amazon.com/dp/B091516D2Z/',
  'https://www.amazon.com/dp/B091T435PZ/',
  'https://www.amazon.com/dp/B092KCMY55/',
  'https://www.amazon.com/dp/B093XWZYNQ/',
  'https://www.amazon.com/dp/B09FG7YR3X/',
  'https://www.amazon.com/dp/B09KBL7D1V/',
  'https://www.amazon.com/dp/B09XV2TRL1/',
  'https://www.amazon.com/dp/B09ZMFPCSS/',
  'https://www.amazon.com/dp/B09ZMFPCSS/',
  'https://www.amazon.com/dp/B09ZQ8Y948/',
  'https://www.amazon.com/dp/B0B877G4ZG/',
  'https://www.amazon.com/dp/B0BDLNZ74R/',
  'https://www.amazon.com/dp/B0BMSN6WCR/',
  'https://www.amazon.com/dp/B0BSJCNQQG/',
  'https://www.amazon.com/dp/B0CBSKP21N/',
  'https://www.amazon.com/dp/B0CJMN6SRW/',
  'https://www.amazon.com/dp/B0CMSGKMBC/',
  'https://www.amazon.com/Funny-Pranks-Annoying-Noise-Maker/dp/B08KG6XHN1/',
  'https://www.amazon.com/HAPPYBAG-Dimmable-Nightlight-Rechargeable-Breastfeeding/dp/B0BGBMZMMQ/',
  'https://www.amazon.com/Loftus-Gross-Party-Pooper-inches/dp/B000V9HCO2/',
  'https://www.amazon.com/Middle-Finger-Duck-Figurine-Statue/dp/B0CCVQTZJH/',
  'https://www.amazon.com/New-Phone-Who-Dis-Adult/dp/B07PZF5C2J/',
  'https://www.amazon.com/New-Phone-Who-Dis-Adult/dp/B07PZF5C2J/',
  'https://www.amazon.com/Pieces-Accessory-Costume-Sharks-Adults/dp/B081JNWBB9/',
  'https://www.amazon.com/Pixelated-Sunglasses-Mosaic-Glasses-Adults/dp/B07K9JX626/ref=sr_1_3?crid=3LADSXCO8HJQB&dib=eyJ2IjoiMSJ9._4yzbo4fSxB_DkJnfTKYK3USS8LWGXA7rYnWOa236FilaLErIoN-YtKNan3T14BAGbIFKjeh12cj1nOEhWb8y3suPn_xPUk0Dfl-vkx7mwxkcgd4x7Fq0auRalkilFz8UJGRcj23raCQoRH1Sx7Iv34Ztm0MdUvPwVGii81SF-aR6bS3DR1ICXelEz4PZ66inq20RUDQguzngHyfvfGUYjQedcR6wY9CcEOGxX2m6pBs_BSZzY8k1ROpbEH5a4bSx5n07K0-t-dz2vatEcf8wrgWRY142SsM7W3Ppezc1To.qNLsJmEjmDvsgjeD5vvnEK6CoxGV9FAz502fUbJnN7w&dib_tag=se&keywords=thug%2Blife&qid=1722982773&sprefix=thug%2Blif%2Caps%2C188&sr=8-3&th=1',
  'https://www.amazon.com/Swole-Doge-Vinyl-Figure-Buff/dp/B09KKGSY67/',
  'https://www.amazon.com/Warmtree-Novelty-Halloween-Costume-Cosplay/dp/B0C7CHVVMQ/',
  // ... rest of your product URLs
];