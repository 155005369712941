import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Share2, Info, Twitter, Facebook, X, Sparkles, Gamepad, Gift, ChevronLeft, ChevronRight } from 'lucide-react';
import { Switch } from './components/ui/switch';
import { urls as initialUrls, gameUrls as initialGameUrls } from './urls';
import productsData from './products.json';

interface Product {
  title: string;
  unNormalizedProductUrl: string;
  highResolutionImages: string[];
  description: string | null;
  price: {
    value: number;
    currency: string;
  } | null;
}

interface AnimatedButtonProps {
  onClick: () => void;
  className: string;
  children: React.ReactNode;
  animateOnClick?: boolean;
  title?: string;
  type?: "button" | "submit" | "reset";
}

interface PopupProps {
  title: string;
  content: React.ReactNode;
  onClose: () => void;
}

interface HomePageProps {
  onRandomClick: () => void;
  onInfoClick: () => void;
  onAddWebsite: () => void;
  onTerms: () => void;
  onPrivacy: () => void;
}

interface ExternalPageProps {
  url: string;
  onRandom: () => void;
  onShare: () => void;
  onInfo: () => void;
  siteId: string;
  onRandomGame: () => void;
  onRandomProduct: () => void;
}

interface ProductPageProps {
  url: string;
  onBack: () => void;
  onRandom: () => void;
  onShare: () => void;
  onInfo: () => void;
  siteId: string;
  onRandomGame: () => void;
  onRandomProduct: () => void;
}

const ImageSlider: React.FC<{ images: string[], productUrl: string }> = ({ images, productUrl }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  const goToPrevious = (e: React.MouseEvent) => {
    e.stopPropagation();
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = (e: React.MouseEvent) => {
    e.stopPropagation();
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const handleProductClick = () => {
    window.open(productUrl, '_blank');
  };

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div 
      className="relative w-full h-0 pb-[100%]" 
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="absolute inset-0">
        <img 
          src={images[currentIndex]} 
          alt={`Product image ${currentIndex + 1}`} 
          className="w-full h-full object-contain rounded-lg"
        />
        <div className="absolute inset-0 flex">
          {images.length > 1 ? (
            <>
              <div 
                className="w-1/5 h-full cursor-w-resize"
                onClick={goToPrevious}
              >
                {isHovering && (
                  <ChevronLeft 
                    size={36} 
                    className="absolute top-1/2 left-2 transform -translate-y-1/2 text-white opacity-50 pointer-events-none"
                  />
                )}
              </div>
              <div 
                className="w-3/5 h-full cursor-pointer"
                onClick={handleProductClick}
              />
              <div 
                className="w-1/5 h-full cursor-e-resize"
                onClick={goToNext}
              >
                {isHovering && (
                  <ChevronRight 
                    size={36} 
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white opacity-50 pointer-events-none"
                  />
                )}
              </div>
            </>
          ) : (
            <div 
              className="w-full h-full cursor-pointer"
              onClick={handleProductClick}
            />
          )}
        </div>
        {isHovering && images.length > 1 && (
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 transition-opacity duration-300">
            {images.map((_, index) => (
              <button
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  goToSlide(index);
                }}
                className={`w-2 h-2 rounded-full ${
                  index === currentIndex ? 'bg-white' : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const AnimatedButton: React.FC<AnimatedButtonProps> = ({ onClick, className, children, animateOnClick, ...props }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    if (animateOnClick) {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 300);
    }
    onClick();
  };

  return (
    <button onClick={handleClick} className={`${className} transform transition-all duration-200 hover:scale-105 active:scale-95 ${isAnimating ? 'animate-pulse' : ''}`} {...props}>
      {children}
    </button>
  );
};

const Popup: React.FC<PopupProps> = ({ title, content, onClose }) => {
  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleOutsideClick}>
      <div className="bg-white p-6 rounded-lg max-w-md w-full relative">
        <AnimatedButton onClick={onClose} className="absolute top-2 right-2 p-1 rounded-full hover:bg-gray-200" animateOnClick={true}>
          <X size={24} />
        </AnimatedButton>
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        <div className="mb-8">{content}</div>
      </div>
    </div>
  );
};

const HomePage: React.FC<HomePageProps> = ({ onRandomClick, onInfoClick, onAddWebsite, onTerms, onPrivacy }) => (
  <div className="flex flex-col min-h-screen relative">
    <div className="absolute top-4 right-4">
      <AnimatedButton onClick={onInfoClick} className="p-2 rounded-full bg-gray-300 text-white hover:bg-gray-700 transition-colors duration-200" title="Info" animateOnClick={true}>
        <Info size={24} className="filter grayscale" />
      </AnimatedButton>
    </div>
    <div className="flex-grow flex flex-col items-center justify-center">
      <h1 className="text-6xl font-bold text-center mb-8">Take me to the weird wonderful web</h1>
      <AnimatedButton onClick={onRandomClick} className="px-10 py-6 bg-red-500 text-white rounded-full text-3xl font-bold hover:bg-red-600 transition-colors duration-200" animateOnClick={true}>
        Please
      </AnimatedButton>
    </div>
    <footer className="bg-black text-brown p-4 flex justify-center space-x-8">
      <button onClick={onAddWebsite} className="text-gray-400 hover:underline">Add a Website</button>
      <button onClick={onTerms} className="text-gray-400 hover:underline">Terms and Conditions</button>
      <button onClick={onPrivacy} className="text-gray-400 hover:underline">Privacy Policy</button>
    </footer>
  </div>
);

const ExternalPage: React.FC<ExternalPageProps> = ({ url, onRandom, onShare, onInfo, siteId, onRandomGame, onRandomProduct }) => {
  return (
    <div className="h-screen flex flex-col">
      <div className="flex-grow relative">
        <iframe src={url} className="absolute inset-0 w-full h-full" title="External Content" />
      </div>
      <div className="bg-gray-800 text-gray-400 py-4 px-6 flex items-center justify-between fixed bottom-0 left-0 right-0">
        <AnimatedButton onClick={onShare} className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200" animateOnClick={true}>
          <Share2 size={24} />
        </AnimatedButton>
        <div className="flex items-center space-x-4">
          <AnimatedButton onClick={onRandomGame} className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center" animateOnClick={true} title="Random Game">
            <Gamepad size={24} />
          </AnimatedButton>
          <AnimatedButton onClick={onRandom} className="p-4 bg-red-500 text-white rounded-full text-2xl font-bold hover:bg-red-600 transition-colors duration-200 flex items-center justify-center" animateOnClick={true} title="Randomize">
            <Sparkles size={30} />
          </AnimatedButton>
          <AnimatedButton onClick={onRandomProduct} className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-200 flex items-center justify-center" animateOnClick={true} title="Random Product">
            <Gift size={24} />
          </AnimatedButton>
        </div>
        <AnimatedButton onClick={onInfo} className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200" animateOnClick={true}>
          <Info size={24} className="filter grayscale" />
        </AnimatedButton>
      </div>
    </div>
  );
};

const ProductPage: React.FC<ProductPageProps> = ({ url, onBack, onRandom, onShare, onInfo, siteId, onRandomGame, onRandomProduct }) => {
  const product = JSON.parse(url) as Product;

  const handleProductClick = () => {
    window.open(product.unNormalizedProductUrl, '_blank');
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 overflow-y-auto pb-36">
        <div className="container mx-auto px-4 py-8 flex items-center justify-center min-h-full">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-4xl w-full">
            <div className="flex flex-col lg:flex-row gap-8 items-center">
              <div className="w-full lg:w-1/2 max-w-md">
                <ImageSlider images={product.highResolutionImages} productUrl={product.unNormalizedProductUrl} />
              </div>
              <div className="w-full lg:w-1/2 flex flex-col justify-between">
                <div>
                  <h1 
                    className="text-3xl font-bold mb-4 cursor-pointer hover:underline text-center lg:text-left"
                    onClick={handleProductClick}
                  >
                    {product.title}
                  </h1>
                  <p className="text-gray-600 mb-6 text-lg text-center lg:text-left">{product.description || ''}</p>
                </div>
                <div className="flex flex-col lg:flex-row justify-between items-center space-y-4 lg:space-y-0">
                  {product.price ? (
                    <p className="text-black text-2xl font-bold">{product.price.currency}{product.price.value.toFixed(2)}</p>
                  ) : (
                    <p className="text-gray-600 text-2xl"></p>
                  )}
                  <AnimatedButton 
                    onClick={handleProductClick} 
                    className="px-6 py-3 bg-orange-500 text-white rounded-full text-lg font-bold hover:bg-orange-600 transition-colors duration-200" 
                    animateOnClick={true}
                  >
                    CHECK IT OUT
                  </AnimatedButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductPageBottomBar onBack={onBack} onRandom={onRandom} onShare={onShare} onInfo={onInfo} siteId={siteId} onRandomGame={onRandomGame} onRandomProduct={onRandomProduct} />
    </div>
  );
};

const ProductPageBottomBar: React.FC<Omit<ProductPageProps, 'url'>> = ({
  onBack,
  onRandom,
  onShare,
  onInfo,
  siteId,
  onRandomGame,
  onRandomProduct,
}) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-gray-400 py-4 px-6 flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <AnimatedButton onClick={onShare} className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200" animateOnClick={true}>
          <Share2 size={24} />
        </AnimatedButton>
      </div>
      <div className="flex items-center space-x-4">
        <AnimatedButton onClick={onRandomGame} className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center" animateOnClick={true} title="Random Game">
          <Gamepad size={24} />
        </AnimatedButton>
        <AnimatedButton onClick={onRandom} className="p-4 bg-red-500 text-white rounded-full text-2xl font-bold hover:bg-red-600 transition-colors duration-200 flex items-center justify-center" animateOnClick={true} title="Randomize">
          <Sparkles size={30} />
        </AnimatedButton>
        <AnimatedButton onClick={onRandomProduct} className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-200 flex items-center justify-center" animateOnClick={true} title="Random Product">
          <Gift size={24} />
        </AnimatedButton>
      </div>
      <div className="flex items-center space-x-4">
        <AnimatedButton onClick={onInfo} className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200" animateOnClick={true}>
          <Info size={24} className="filter grayscale" />
        </AnimatedButton>
      </div>
    </div>
  );
};

// Main App Component
const App: React.FC = () => {
  const [urls, setUrls] = useState<string[]>(initialUrls);
  const [gameUrls, setGameUrls] = useState<string[]>(initialGameUrls);
  const [products, setProducts] = useState<Product[]>([]);
  const [currentUrl, setCurrentUrl] = useState<string>('');
  const [currentSiteId, setCurrentSiteId] = useState<string>('');
  const [previousUrls, setPreviousUrls] = useState<string[]>([]);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showAddWebsite, setShowAddWebsite] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
  const [showShare, setShowShare] = useState<boolean>(false);
  const [isExternalPage, setIsExternalPage] = useState<boolean>(false);
  const [newWebsiteUrl, setNewWebsiteUrl] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [subscribeNewsletter, setSubscribeNewsletter] = useState<boolean>(false);
  const [shareUrl, setShareUrl] = useState<string>('');
  const [isProductPage, setIsProductPage] = useState<boolean>(false);

  useEffect(() => {
    setProducts(productsData as Product[]);
    const urlParams = new URLSearchParams(window.location.search);
    const siteId = urlParams.get('site');
    const type = urlParams.get('type');

    if (siteId) {
      const urlIndex = parseInt(siteId, 10);
      if (!isNaN(urlIndex)) {
        if (type === 'product' && urlIndex >= 0 && urlIndex < productsData.length) {
          setCurrentUrl(JSON.stringify(productsData[urlIndex]));
          setCurrentSiteId(siteId);
          setIsExternalPage(true);
          setIsProductPage(true);
        } else if (urlIndex >= 0 && urlIndex < urls.length) {
          setCurrentUrl(urls[urlIndex]);
          setCurrentSiteId(siteId);
          setIsExternalPage(true);
          setIsProductPage(false);
        }
      }
    }
  }, [urls]);

  const getRandomUrl = (): [string, string] => {
    const randomIndex = Math.floor(Math.random() * urls.length);
    return [urls[randomIndex], randomIndex.toString()];
  };

  const handleRandomClick = () => {
    const [newUrl, newSiteId] = getRandomUrl();
    setPreviousUrls(prev => [...prev, currentUrl]);
    setCurrentUrl(newUrl);
    setCurrentSiteId(newSiteId);
    setIsExternalPage(true);
    setIsProductPage(false);
    window.history.pushState(null, '', `/site=${newSiteId}`);
  };


const handleRandomGame = () => {
  const randomIndex = Math.floor(Math.random() * gameUrls.length);
  const newUrl = gameUrls[randomIndex];
  setPreviousUrls(prev => [...prev, currentUrl]);
  setCurrentUrl(newUrl);
  setCurrentSiteId(randomIndex.toString());
  setIsExternalPage(true);
  setIsProductPage(false);
  window.history.pushState(null, '', `/site=${randomIndex}&type=game`);
};

const handleRandomProduct = () => {
  const randomIndex = Math.floor(Math.random() * products.length);
  const newProduct = products[randomIndex];
  setPreviousUrls(prev => [...prev, currentUrl]);
  setCurrentUrl(JSON.stringify(newProduct));
  setCurrentSiteId(randomIndex.toString());
  setIsExternalPage(true);
  setIsProductPage(true);
  window.history.pushState(null, '', `/site=${randomIndex}&type=product`);
};

const handleBackToMain = () => {
  setIsExternalPage(false);
  setIsProductPage(false);
  window.history.pushState(null, '', '/');
};

const handleInfoClick = () => setShowInfo(true);

const handleAddWebsite = () => setShowAddWebsite(true);

const handleTermsClick = () => setShowTerms(true);

const handlePrivacyClick = () => setShowPrivacy(true);

const handleShare = () => {
  const shareUrl = `${window.location.origin}/?site=${currentSiteId}`;
  setShareUrl(shareUrl);
  setShowShare(true);
};

const handleSubmitNewWebsite = (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  if (newWebsiteUrl && !urls.includes(newWebsiteUrl)) {
    setUrls(prev => [...prev, newWebsiteUrl]);
    setNewWebsiteUrl('');
    setEmail('');
    setShowAddWebsite(false);
    setShowConfirmation(true);
  }
};

return (
  <div className="min-h-screen flex flex-col">
    <Helmet>
      <title>Weird Wonderful Web - Discover Random Interesting Websites</title>
      <meta name="description" content="Explore the weird and wonderful corners of the internet with our random website generator. Discover unique and interesting websites with just one click!" />
      <meta property="og:image" content="https://example.com/path-to-your-image.jpg" />
      <meta property="og:title" content="Weird Wonderful Web - Random Website Discovery" />
      <meta property="og:description" content="Discover unique and interesting websites with our random website generator. Click to explore the weird and wonderful web!" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content="https://example.com/path-to-your-image.jpg" />
    </Helmet>
    {isExternalPage ? (
      isProductPage ? (
        <ProductPage url={currentUrl} onBack={handleBackToMain} onRandom={handleRandomClick} onShare={handleShare} onInfo={handleInfoClick} siteId={currentSiteId} onRandomGame={handleRandomGame} onRandomProduct={handleRandomProduct} />
      ) : (
        <ExternalPage url={currentUrl} onRandom={handleRandomClick} onShare={handleShare} onInfo={handleInfoClick} siteId={currentSiteId} onRandomGame={handleRandomGame} onRandomProduct={handleRandomProduct} />
      )
    ) : (
      <HomePage onRandomClick={handleRandomClick} onInfoClick={handleInfoClick} onAddWebsite={handleAddWebsite} onTerms={handleTermsClick} onPrivacy={handlePrivacyClick} />
    )}
    {showInfo && (
      <Popup title="How it works" content="Click the button to be taken to a random weird wonderful website. Use the bottom bar to navigate, share, or get more information. Each page has a unique link that you can share with others!" onClose={() => setShowInfo(false)} />
    )}
    {showAddWebsite && (
      <Popup title="Add a Website" content={
        <form onSubmit={handleSubmitNewWebsite}>
          <input type="url" value={newWebsiteUrl} onChange={(e) => setNewWebsiteUrl(e.target.value)} placeholder="Enter website URL" className="w-full p-2 border border-gray-300 rounded mb-4" required />
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email address" className="w-full p-2 border border-gray-300 rounded mb-4" required />
          <div className="flex items-center justify-between mb-4">
            <span>Subscribe to newsletter</span>
            <Switch checked={subscribeNewsletter} onCheckedChange={setSubscribeNewsletter} className="data-[state=checked]:bg-gray-100" />
          </div>
          <div className="flex justify-center">
            <AnimatedButton type="submit" className="px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200" animateOnClick={true} onClick={() => { }}>
              Submit Your Website
            </AnimatedButton>
          </div>
        </form>
      } onClose={() => setShowAddWebsite(false)} />
    )}
    {showTerms && (
      <Popup title="Terms and Conditions" content={
        <div>
          <p>Welcome to WeirdWonderfulWeb. By using our service, you agree to the following terms:</p>
          <ul className="list-disc pl-5 mt-2">
            <li>We are not responsible for the content of external websites.</li>
            <li>Use this service at your own risk and discretion.</li>
            <li>Do not use this service for any illegal activities.</li>
            <li>We reserve the right to modify or terminate the service at any time.</li>
          </ul>
        </div>
      } onClose={() => setShowTerms(false)} />
    )}
    {showPrivacy && (
      <Popup title="Privacy Policy" content={
        <div>
          <p>At WeirdWonderfulWeb, we respect your privacy:</p>
          <ul className="list-disc pl-5 mt-2">
            <li>We do not collect any personal information.</li>
            <li>Your email address is only used for newsletter subscriptions.</li>
            <li>We do not track your browsing history or the websites you visit.</li>
            <li>We use cookies only for essential functionality of the service.</li>
          </ul>
        </div>
      } onClose={() => setShowPrivacy(false)} />
    )}
    {showShare && (
      <Popup title="Share this weird wonderful website" content={
        <div>
          <p className="mb-4">Share this unique link:</p>
          <input type="text" value={shareUrl} readOnly className="w-full p-2 border border-gray-300 rounded mb-4" />
          <div className="flex justify-center space-x-4">
            <AnimatedButton onClick={() => window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`, '_blank')} className="p-2 bg-blue-400 rounded-full hover:bg-blue-500 transition-colors duration-200" animateOnClick={true}>
              <Twitter size={24} className="text-white" />
            </AnimatedButton>
            <AnimatedButton onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank')} className="p-2 bg-blue-600 rounded-full hover:bg-blue-700 transition-colors duration-200" animateOnClick={true}>
              <Facebook size={24} className="text-white" />
            </AnimatedButton>
          </div>
        </div>
      } onClose={() => setShowShare(false)} />
    )}
    {showConfirmation && (
      <Popup title="Confirmation" content={
        <div>
          <p>Thank you for adding a website{subscribeNewsletter ? " and subscribing to our newsletter" : ""}!</p>
          <p>Your submission has been received and will be reviewed shortly.</p>
        </div>
      } onClose={() => setShowConfirmation(false)} />
    )}
  </div>
);
};

export default App;